import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`What's up with the first quarter of year 2022?`}</h1>
    <h2>{`Success, maybe?`}</h2>
    <p>{`After all that buzz in the year 2021, finally I've managed to somehow improve myself, I've finally earned my first certification, which is a the MuleSoft Certified Developer - Level 1, finally learned some Kubernetes, Docker and a lot of other things. `}</p>
    <p>{`A little bit of NestJS here, a little bit of Ethereum there, a little bit of RemixJS here, and a little bit of Jenkins there. However due to a specific happening in my life, I felt like all of these that I am working on feels very misguided, and that event is `}<strong parentName="p">{`looking for another job`}</strong>{`.`}</p>
    <h2>{`Machiavelli`}</h2>
    <p>{`As people might have noticed, I really don't have a specific focus in mind, and these things I do I am not doing in a professional capacity, meaning, I don't use Kubernetes in my own work, nor I use NestJS. And thus all of it became useless when a `}<em parentName="p">{`certain narcissistic and machiavellian man`}</em>{` came into my professional life and basically ruined everything, forcing me to look for another job to save what is precious, which is my growth.`}</p>
    <p>{`I understand machiavellianism, I understand what he wants to achieve and how, however, since he was a narcissist, he kinda made himself look like an ass and did assy things and said assy things undermining everything we did for the past year without really understanding why things are that way.`}</p>
    <p>{`One good example is that the team haven't delivered anything for a long time, and he trashed us for that, well yeah duh how are we supposed to deliver anything when we don't know what we are supposed to do. We aren't the client, and if the client failed to give us all the requirments on time, then what? Berate us? Well apparently he thinks that is a good idea. We are so hungry for work in fact, despite being Developers, we did Business Analyst things, and some System Analysis things. `}</p>
    <p>{`And yes basically the whole team left after that, they are in fact wanted to leave so bad I manage to flex the fact that I will be leaving before them. Because I saw it already, and did very little thinking on the things that I need to do. I just assessed his characters and I was like, time to get out, and I did. And so far I am very happy with my decision. And it was so right in fact I can flex it when they talk about it. The fact that I can flex that I will be leaving 2 weeks earlier than them says a lot about the situation. `}</p>
    <p>{`One thing people should know is that, overwork usually don't equal growth when it comes to software engineering. Usually what happens when Software Engineers get overworked they usually stagnate. What kind of situation we want to put our Developers are they in that spot that they are challenged, but not overworked. If the Developers are overworked, at that point they will just lose their soul and their imagination with it. Therefore producing a Developer who is unimaginative and unsophisticated with how they do things. At least that's what I am able to see when I've interviewed a few people who the story of their life is being "overworked".`}</p>
    <p>{`So I've avoided that situation by resigning from my work despite having nothing to fall back on, and when I was applying for jobs, I've learned that my experience in Java so far is so bad (because of 2 bad employers) I have a better chance of getting accepted as a .NET developer again. Among all of those I've also applied for all kinds of position, I covet a position in a blockchain based company. I've applied for Solidity jobs too, MuleSoft jobs too.`}</p>
    <p>{`What happened is I got accepted in a .NET position. Because that is what I can sell best despite not really using it for 1 year. And now I feel the impostor is real due to the fact that, I really don't deserve this one, I got accepted as a senior developer and it sounds like, i'll be eating more than I can chew this time.`}</p>
    <h2>{`Elden Ring`}</h2>
    <p>{`And despite all of that, something gave me a very important lesson. `}<strong parentName="p">{`With struggle comes success`}</strong>{` that something is Elden Ring, which I took as if its my full time job for the last 3 weeks. A lesson that cost me around 70 hours and half of my Kubernetes knowledge, tons of my NestJS knowledge, now I don't even remember a thing. (Which is a big concern to me)`}</p>
    <p>{`With that, I've also learned that confidence can come from this thing called `}<strong parentName="p">{`self-efficacy`}</strong>{` or as it is defined, an individual's belief in his or her capacity to execute behaviors necessary to produce specific performance attainments. `}</p>
    <p>{`The fact that with enough struggle, with enough fight, things can turn for the better. Perhaps in my new job all I need is to fight and struggle, of course, do it in the smartest way I can. Tons of money is on the line.`}</p>
    <p>{`Am I in that condition that I can put my soul on the job? Perhaps, As long as its not like in F`}{`*`}{`*`}{`*`}{`*`}{`*`}{`u where I need to do tons of documents and need to meet with my manager twice a day just to code 10 lines. Never again I'll do that.`}</p>
    <p>{`And yes, I've also delayed my certification adventures by a month because Elden Ring, I know I should blame myself on this but. Yeah, I played too much Elden Ring.`}</p>
    <h2>{`In Conclusion`}</h2>
    <p>{`I've managed to get my first certification which I can't use. Quit a job because somebody did assy things, looked for a job and manage to get one which I think I really cannot perform in, played Elden Ring and now think I can do it. And all of my certification adventures were delayed by a month.`}</p>
    <p>{`I would say, a good first quarter. 8/10.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      